import React, { useState } from "react";
import "../index.css";

import deepleLogo from "../../../../static/1convo-logo.svg";

const TopNav = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  return (
    <div className="site-nav">
      <div className="site-nav-container">
        <nav
          className="site-nav-desktop-only align-center"
          aria-label="primary"
        >
          <div>
            <a href="/">
              <img src={deepleLogo} alt="1convo advanced AI chatbot" />
            </a>
          </div>
        </nav>

        <div className="site-nav-mobile-only site-nav-mobile-logo">
          <a href="/">
            <img
              src={deepleLogo}
              alt="1convo advanced AI chatbot"
              style={{ marginTop: 18 }}
            />
          </a>
        </div>
        <ul className="site-nav-actions">
          <li className="site-nav-actions-item site-nav-desktop-only">
            <a href="/pricing">Pricing</a>
          </li>
          <li className="site-nav-actions-item site-nav-desktop-only">
            <a href="/contact">Contact Sales</a>
          </li>
        </ul>
        <div
          className="site-nav-mobile-only align-center site-nav-mobile-menu-toggle cursor-pointer"
          aria-label="Toggle Navigation"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0732 0H2.92683C1.31039 0 0 1.34315 0 3V37C0 38.6569 1.31039 40 2.92683 40H37.0732C38.6896 40 40 38.6569 40 37V3C40 1.34315 38.6896 0 37.0732 0Z"
              fill="white"
              fillOpacity="0.5"
            />
            <path
              d="M30 14H10C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16H30C30.5523 16 31 15.5523 31 15C31 14.4477 30.5523 14 30 14Z"
              fill="white"
            />
            <path
              d="M30 19H10C9.44772 19 9 19.4477 9 20C9 20.5523 9.44772 21 10 21H30C30.5523 21 31 20.5523 31 20C31 19.4477 30.5523 19 30 19Z"
              fill="white"
            />
            <path
              d="M30 24H10C9.44772 24 9 24.4477 9 25C9 25.5523 9.44772 26 10 26H30C30.5523 26 31 25.5523 31 25C31 24.4477 30.5523 24 30 24Z"
              fill="white"
            />
          </svg>

          {isOpenMenu && (
            <div className="site-nav-menu-dropdown-wrapper flex flex-col">
              <div className="flex w-full px-3 py-2">
                <a href="/" className="text-black">
                  Home
                </a>
              </div>
              <div className="flex w-full px-3 py-2">
                <a href="/pricing" className="text-black">
                  Pricing
                </a>
              </div>
              <div className="flex w-full px-3 py-2">
                <a href="/contact" className="text-black">
                  Contact Sales
                </a>
              </div>
              <div className="flex w-full px-3 py-2">
                <a href="https://tutorials.1convo.ai/" className="text-black">
                  Tutorials
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNav;
