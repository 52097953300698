import React from "react";
import "./index.css";

type CardSize = "large" | "medium" | "small";

type CardPropsType = {
  className?: string;
  size: CardSize;
};

const Card: React.FC<CardPropsType> = (props) => {
  const { children, className, size } = props;
  return (
    <div className={`dp-card dp-card-size-${size} ${className}`}>
      {children}
    </div>
  );
};

export default Card;
