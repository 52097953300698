import React from "react";
import "./index.css";
import { AllPlanFeature } from "./TableData";
import ReactTooltip from "react-tooltip";
import { enabledIcon, infoIcon } from "./icons";
import { COLUMN_LENGTH, getIconTypeAndText } from "./PricingTable";

const PricingTableMobile = ({ showIndex = 0 }) => {
  const featureHeader = (title: string) => {
    return (
      <div className="flex justify-between table-header mt-2">
        <div
          className="pl-2 font-medium"
          style={{ color: "#6353EA", fontSize: 16 }}
        >
          {title}
        </div>
        <div
          className="flex justify-between space-x-2 text-black font-medium"
          style={{ fontSize: 16 }}
        >
          {showIndex === 0 && (
            <div className="w-32 text-center">7-Day Trial</div>
          )}
          {showIndex === 1 && <div className="w-32 text-center">Paid Plan</div>}
          {showIndex === 2 && <div className="w-32 text-center">Custom</div>}
        </div>
      </div>
    );
  };

  const featureRow = (item: {
    title: string;
    info: string;
    disabled?: boolean[];
    detail?: string[];
    prefixDetail?: string[];
    discount?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mixValue?: any[];
  }) => {
    return (
      <div
        className="flex justify-between items-center"
        style={{ color: "#737D94", fontSize: 14, height: "50px" }}
      >
        <div className="flex pl-4">
          {item.title}
          {item.info && (
            <p
              className="ml-2.5 cursor-pointer flex align-center"
              data-text-color="white"
              data-padding="0px 8px"
              data-background-color="#B6BAC8"
              data-place="top"
              data-tip={item.info}
              data-event="click"
            >
              {infoIcon}
            </p>
          )}
        </div>
        <ReactTooltip />
        <div className="flex justify-between space-x-2 items-center h-full">
          <>
            {item?.disabled?.map((disable, index) => {
              if (showIndex === index)
                return (
                  <div
                    className="flex w-32 text-center justify-center items-center"
                    key={index}
                  >
                    {getIconTypeAndText(disable)}
                  </div>
                );
            })}

            {item?.detail?.map((detail, index) => {
              if (showIndex === index)
                return (
                  <div
                    className="w-32 text-center relative h-full flex justify-center align-center"
                    key={index}
                  >
                    <div className="h-full flex align-center justify-end">
                      {item.discount && item.discount[index] && (
                        <span
                          className="strikethrough absolute text-white top-0 rounded-sm p-0.5"
                          style={{
                            background: "#8778FF",
                            fontSize: "9px",
                          }}
                        >
                          {item.discount[index]}
                        </span>
                      )}
                      <div>
                        {item.prefixDetail && (
                          <span className="mr-0.5" style={{ fontSize: "6px" }}>
                            {item.prefixDetail[index]}
                          </span>
                        )}
                        <span>{getIconTypeAndText(detail)}</span>
                      </div>
                    </div>
                  </div>
                );
            })}

            {item?.mixValue?.map((value, index) => {
              if (showIndex === index)
                return (
                  <div
                    className="flex w-32 text-center justify-center items-center"
                    key={index}
                  >
                    {getIconTypeAndText(value)}
                  </div>
                );
            })}
            {/* Note: to fill all empty item with enabledIcon */}
            {!item?.detail &&
              !item?.disabled &&
              !item?.mixValue &&
              COLUMN_LENGTH.map((detailIndex) => {
                if (showIndex === detailIndex)
                  return (
                    <div
                      className="flex w-32 text-center justify-center"
                      key={detailIndex}
                    >
                      {enabledIcon}
                    </div>
                  );
              })}
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="md:hidden mt-12">
      <div
        style={{
          maxWidth: "1150px",
        }}
        className="flex sm:justify-start xl:justify-center flex-row items-stretch space-x-4 space-y-0 pb-10 mx-auto"
      >
        <section className="flex flex-col w-full px-2 py-2 space-y-2 bg-white rounded-lg shadow-card">
          {featureHeader("ฟีเจอร์ช่วยขายผ่านแชท")}
          <div>
            {AllPlanFeature.chatAndShop.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("ฟีเจอร์ช่วยจัดการระบบหลังร้าน")}
          <div>
            {AllPlanFeature.storeManagement.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การจัดส่ง")}
          <div>
            {AllPlanFeature.shipping.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การเชื่อมต่อ")}
          <div>
            {AllPlanFeature.integration.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การเข้าใช้งานระบบหลังร้าน")}
          <div>
            {AllPlanFeature.consoleAccessibility.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("ฟีเจอร์เสริม")}
          <div>
            {AllPlanFeature.AddOn.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("1convo pay")}
          <div>
            {AllPlanFeature.deeplePay.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การชำระผ่านการโอน")}
          <div>
            {AllPlanFeature.bankTransfer.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PricingTableMobile;
