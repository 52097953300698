export type PurposeOption = {
  readonly label: string;
  readonly value: string;
};

export const PURPOSE_OPTIONS: readonly PurposeOption[] = [
  { value: "ปิดการขายสินค้า", label: "ปิดการขายสินค้า" },
  { value: "ช่วยตอบแชทแทนแอดมิน", label: "ช่วยตอบแชทแทนแอดมิน" },
  {
    value: "ให้คำปรึกษาเชิงลึกเกี่ยวกับสินค้าและบริการ",
    label: "ให้คำปรึกษาเชิงลึกเกี่ยวกับสินค้าและบริการ",
  },
  {
    value: "จัดการออเดอร์และสต็อกสินค้า",
    label: "จัดการออเดอร์และสต็อกสินค้า",
  },
  {
    value: "เชื่อมต่อระบบชำระเงิน ขนส่ง หรืออื่น ๆ",
    label: "เชื่อมต่อระบบชำระเงิน ขนส่ง หรืออื่น ๆ",
  },
];
