import React from "react";
import "./index.css";

import GradientAnimation from "../GradientAnimation";
import TopNav from "../TopNav";

import HomeBanner from "../HomeBanner";

import TryFreeTrial from "../TryFreeTrial";
import Footer from "../Footer";
import GridContainer from "../GridContainer";

const Platform: React.FC = () => (
  <>
    <GradientAnimation />

    <div className="center-container">
      <TopNav />
    </div>

    <GridContainer>
      <HomeBanner />
    </GridContainer>

    <GridContainer>
      <div>platform content</div>
    </GridContainer>
    <GridContainer>
      <TryFreeTrial />
    </GridContainer>

    <div>
      <Footer />
    </div>
  </>
);

export default Platform;
