import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "../index.css";
import LottiePlayer from "../../LottiePlayer";

type CardPropsType = {
  children?: React.ReactNode;
};

const CardScrollerFeature: React.FC<CardPropsType> = () => {
  const cardDetails = [
    {
      text1: "",
      text2: "ระบบจัดการร้านค้าครบวงจร",
      videoLink: null,
      image: (
        <StaticImage
          alt={"ระบบจัดการร้านค้าครบวงจร"}
          src={"../../../../static/image/home-store.png"}
          placeholder="blurred"
          height={173}
          width={360}
        />
      ),
    },
    {
      text1: "",
      text2: "AI Chatbot ช่วยขาย 24 ชม.",
      videoLink: null,
      image: (
        <StaticImage
          alt={"AI Chatbot ช่วยขาย 24 ชม."}
          src={"../../../../static/image/conversation.png"}
          placeholder="blurred"
          height={130.72}
          width={360}
        />
      ),
      background: "bg-black",
      textColor: "text-white",
    },
    {
      text1: "",
      text2: "ใช้งานผ่านแพลตฟอร์มแชทยอดนิยม",
      videoLink: null,
      image: (
        <StaticImage
          alt={"ใช้งานผ่านแพลตฟอร์มแชทยอดนิยม"}
          src={"../../../../static/image/social-icon.png"}
          placeholder="blurred"
          height={153}
          width={360}
        />
      ),
    },
  ];

  return (
    <div className="relative pt-8">
      <div className="card-scroller-container p-6">
        <div>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            ตอบโจทย์การขายออนไลน์ครบวงจร&nbsp;
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            ตั้งแต่ลูกค้าทักแชทถึงจัดส่งสินค้า
          </span>
        </div>
      </div>
      <div className="dp-cards-scroller dp-card-height-260">
        <div className="dp-cards-scroller-crop">
          <div className="dp-cards-scroller-content">
            <div className="dp-cards-scroller-platter">
              {cardDetails.map((card) => {
                return (
                  <div
                    className="dp-cards-scroller-item-view dp-card-height-220"
                    key={card.text2}
                  >
                    <div className="dp-card-width-360">
                      <div
                        className={`dp-card-content dp-card-height-220 ${
                          card?.background || "bg-white"
                        }`}
                      >
                        <div className="dp-card-img-full-wrapper mt-10">
                          <div className="mt-10">
                            {card.videoLink && (
                              <LottiePlayer
                                key={card.text1}
                                file={card.videoLink}
                                height={140}
                                width={300}
                              />
                            )}
                            {card.image}
                          </div>
                        </div>
                        <div className="dp-card-content-info">
                          <h3 className="dp-card-content-eyebrow">
                            {card.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <a className="dp-card-content-header-link">
                              <div
                                className={`dd-expand-right-small-2 ${
                                  card?.textColor || "text-black"
                                }`}
                              >
                                {card.text2}
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrollerFeature;
