import { PageProps } from "gatsby";
import React from "react";

const PrivacyPage: React.FC<PageProps> = () => {
  return (
    <div className="container mx-auto my-8 md:my-24 px-4 md:px-32">
      <h1 className="text-4xl pb-3">Privacy policy</h1>
      <p className="text-sm font-graphikthloop">
        While the information is an important thing for everyone today. To serve
        customers is our concern. But the most important thing for us is our
        customers&#x27; relying on us. Therefore, we,{" "}
        <strong>Kanda Digital Co., Ltd.</strong>, with its located at 99 3rd
        Floor, L3-A03 Room, Ramkhamhaeng Rd, Suan Luang, Suan Luang, Bangkok
        10250
        <strong> (&quot;1Convo&quot;)</strong> hereby declare our policy to
        safeguard confidential information of our customers or individual who
        visits our web site.
      </p>
      <h4 className="text-xl py-3">
        <strong>For Visiting our web site</strong>
      </h4>
      <p className="text-sm font-graphikthloop pb-2">
        You may visit our web site and find out our products and services, check
        our rates, and read more about us without giving us any information
        about yourself. 1Convo is committed to continuous improvement of our web
        site.
        <br />
      </p>
      <p className="text-sm font-graphikthloop pb-2">
        We may use software tools to gather information about site
        visitors&#x27; browsing activities in order to target areas for
        improvement, gathered Information may include date and time of visits,
        pages viewed, time spent at the site, browser types, internet service
        provider, and the site visited just before and just after the 1Convo
        site.
        <br />
      </p>
      <p className="text-sm font-graphikthloop">
        We will also track browsing activities of customers who have accounts
        with 1Convo and who use our Chatbot System Services. As part of our
        security procedures, 1Convo may use a &quot;cookie&quot; to authenticate
        a user&#x27;s request. A &quot;cookie&quot; is information sent from a
        website to a visitor&#x27;s computer while he or she is visiting the
        site. The cookies set by 1Convo will expire after online Chatbot
        session.
      </p>
      <h4 className="text-xl py-3">
        <strong>Collection and Retention of Personal Information</strong>
      </h4>
      <p className="text-sm font-graphikthloop pb-2">
        Information regarding our customers is used solely in the legitimate
        conduct of our business, to deliver superior service and to design
        products and special offers that is betterment to meet our
        customer&#x27;s needs. Customer information will not be used, collected,
        or retained unless 1Convo has a legitimate business purpose to do so. We
        will collect personal information directly from you through requests for
        information, the application process, and value-added services that
        require personal contact with you. If you choose to provide personal
        information, such as name, address, e-mail, telephone and fax numbers,
        as well as demographic and customer identification, we will maintain
        this information, as well as your business activities and transactions,
        according to our usual strict security and confidentiality standards.
      </p>
      <p className="text-sm font-graphikthloop">
        Our customer&#x27;s information will be collected and retained during
        the course of a business relationship with you. For instance, when you
        send e-mail to 1Convo, we will retain the content of the e-mail, the
        e-mail address and our response in order to handle further questions
        which you may have, or necessary to follow-up, and to monitor how well
        we&#x27;re responding to customer questions and concerns.
      </p>
      <h4 className="text-xl py-3">
        <strong>Disclosure of personal information</strong>
      </h4>
      <p className="text-sm font-graphikthloop pb-2">
        1Convo does not and will not provide or sell your personal information
        to any third party for independent use.
      </p>
      <p className="text-sm font-graphikthloop pb-2">
        Further, we will not disclose your personal information to external
        organizations unless;
      </p>
      <ol role="list" className="list-decimal font-graphikthloop pl-12 pb-2">
        <li className="text-sm font-graphikthloop">
          You request or authorize 1Convo to do so, or
        </li>
        <li className="text-sm font-graphikthloop">
          The information is provided to help complete a transaction initiated
          by you, or
        </li>
        <li className="text-sm font-graphikthloop">
          The information is provided to a reputable credit bureau or similar
          information reporting agency, or
        </li>
        <li className="text-sm font-graphikthloop">
          The disclosure otherwise is lawfully permitted or required, 1Convo may
          be required, just for example, to disclose information about our
          customers. When 1Convo is required by a subpoena or court order.
          1Convo may also be required to share this information with
          governmental regulatory authorities, who is as authorized by
          applicable law.
        </li>
      </ol>
      <p className="text-sm font-graphikthloop pb-2">
        1Convo contracts with the third-party service providers to develop and
        maintain 1Convo &#x27;s transaction processing systems and provide other
        materials or services on 1Convo behalf. All third-party service
        providers working with or on behalf of 1Convo agree to safeguard our
        customer&#x27;s information and must abide by applicable law.
      </p>
      <p className="text-sm font-graphikthloop pb-2">
        Whenever 1Convo employs other organizations to provide support services,
        they are required them to conform to 1Convo &#x27;s privacy standards.
        <br />
      </p>
      <p className="text-sm font-graphikthloop">
        1Convo occasionally enters into joint marketing agreements and/or
        promotions with third parties in order to attract new customers. If you
        apply for an account with 1Convo as a result of such a promotion or
        offer, 1Convo reserves the right to confirm with the third party that
        you have opened an account with us, the type of account and date opened.
      </p>
      <h4 className="text-xl py-4">
        <strong>Security procedures to protect information</strong>
      </h4>
      <p className="text-sm font-graphikthloop">
        1Convo maintains a strong security standard in order to help prevent
        against loss or unauthorized access to your personal information.
        <br />
      </p>
      <h4 className="text-xl py-4">
        <strong>Maintenance of Accurate Information</strong>
      </h4>
      <p className="text-sm font-graphikthloop">
        1Convo has the procedures to assure that your personal information is
        accurate, current, and complete in accordance with commercial standards.
        1Convo also has the procedures to respond to your requests for a
        correction of inaccurate information in timely manner.
        <br />
      </p>
      <h4 className="text-xl py-3">
        <strong>Security Suggestions</strong>
      </h4>
      <p className="text-sm font-graphikthloop pb-2">
        Currently, no security measure that could absolutely prevent your
        information from being attacked by computer virus or worm or being
        accessed by unauthorized persons. Therefore, 1Convo recommends that the
        following procedures should also be observed and followed:
      </p>
      <ul className="list-disc pl-12 pb-2">
        <li className="text-sm font-graphikthloop">
          be careful when download freeware via the Internet. Always check the
          address of the website that you enter before login to prevent
          accessing to false website;
        </li>
        <li className="text-sm font-graphikthloop">
          anti-virus software should always be installed on your personal
          computer and be updated regularly;
        </li>
        <li className="text-sm font-graphikthloop">
          personal firewall software should be installed for protection against
          any crackers or hackers;
        </li>
        <li className="text-sm font-graphikthloop">
          keep yourselves updated in respect of computer news;
        </li>
        <li className="text-sm font-graphikthloop">
          check your financial status regularly for security purpose; such as,
          account balance, transaction date, etc.;
        </li>
        <li className="text-sm font-graphikthloop">
          always sign off the system after completing your transaction. Do not
          leave your computer unattended during the transaction;
        </li>
        <li className="text-sm font-graphikthloop">
          keep your personal information, financial status or credit card number
          confidential. Personal information should not be disclosed on websites
          that are not certified by a trusted Internet security solution
          provider and
        </li>
        <li className="text-sm font-graphikthloop">avoid opening Junk mail.</li>
      </ul>
      <h4 className="text-xl py-3">
        <strong>Changes to this Privacy Policy</strong>
      </h4>
      <p className="text-sm font-graphikthloop">
        1Convo reserves its rights to make any changes to this Privacy Policy at
        any time. 1Convo shall inform you as soon as possible, in case 1Convo
        has made any substantial change that may affect to your privacy right.
      </p>
      <h4 className="text-xl py-3">
        <strong>Communication with 1Convo</strong>
      </h4>
      <p className="text-sm font-graphikthloop">
        If you want any assistance from 1Convo regardless of whether such
        assistance is required in respect of any additional information, any
        errors, any doubts of any transactions previously executed without your
        permission, you yourselves shall have to inform 1Convo by contacting
        1Convo as above our address or e-mail{" "}
        <a
          href="mailto:contact@magnita.ai"
          className="underline text-custom-blue"
        >
          contact@magnita.ai
        </a>
      </p>
    </div>
  );
};

export default PrivacyPage;
