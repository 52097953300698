import React from "react";
import "../../CardScroller/index.css";

type CardPropsType = {
  children?: React.ReactNode;
};

const CardVideo: React.FC<CardPropsType> = () => {
  return (
    <div className="relative">
      <div className="card-scroller-container p-6">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <h3 className="text-white font-weight-600 gradient-text dashboard-heading-2-gradient pb-2 w-fit-content">
              1convo
            </h3>
            <h2 className="cards-shelf-main-header dashboard-heading-2">
              เปลี่ยนทุกการซื้อ-ขายของคุณให้ง่ายขึ้น
            </h2>
          </div>
          <div className="text-custom-mid-gray text-lg leading-9">
            ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์
            ดูดคอมเมนต์ไลฟ์ และช่วยขายครบวงจร สำหรับธุรกิจบน Facebook, Instagram
            และ LINE OA
          </div>
        </div>
      </div>

      <div className="mx-auto" style={{ maxWidth: "1150px" }}>
        <div className="relative px-6">
          <div
            className="relative w-full px-0 pb-0 bg-cover"
            style={{
              paddingTop: "56.25%", // 16:9 ratio
              backgroundPosition: "50% 50%",
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/2Sh4HatURkM"
              frameBorder="0"
              className="absolute left-0 top-0 w-full h-full pointer-events-auto"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardVideo;
