import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import TopNav from "../TopNav";

import HomeBanner from "../HomeBanner";

import CardScrollerShopping from "../CardScroller/Shopping";
import CardScrollerAI from "../CardScroller/AI";
import CardScrollerDeeplePay from "../CardScroller/DeeplePay";
import CardScrollerShipping from "../CardScroller/Shipping";
import CardScrollerFeature from "../CardScroller/Feature";
import CardScrollerStoreManagement from "../CardScroller/StoreManagement";

import TryFreeTrial from "../TryFreeTrial";
import Footer from "../Footer";
import GridContainer from "../GridContainer";

const Home: React.FC = () => {
  return (
    <>
      <StaticImage
        src={"../../../static/image/deeple-store-management-bg.jpg"}
        alt="Header background"
        placeholder="blurred"
        className="absolute header-pink-home"
      />
      <div className="center-container">
        <TopNav isWhiteTheme />
      </div>

      <HomeBanner />

      <GridContainer>
        <CardScrollerFeature />
      </GridContainer>

      <GridContainer>
        <CardScrollerShopping />
      </GridContainer>

      <GridContainer>
        <CardScrollerAI />
      </GridContainer>

      <GridContainer>
        <CardScrollerShipping />
      </GridContainer>

      <GridContainer>
        <CardScrollerDeeplePay />
      </GridContainer>

      <GridContainer>
        <CardScrollerStoreManagement />
      </GridContainer>

      <GridContainer>
        <TryFreeTrial />
      </GridContainer>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
