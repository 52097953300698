import React, { MouseEventHandler } from "react";
import "./index.css";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "dark"
  | "gradient"
  | "gradient-white";
type ButtonSize = "large" | "medium" | "small";

type ButtonPropsType = {
  variant: ButtonVariant;
  size: ButtonSize;
  className?: string;
  type?: "button" | "submit" | "reset";
  onClick?: MouseEventHandler;
};

const Button: React.FC<ButtonPropsType> = (props) => {
  const { children, variant, size, className, onClick, type } = props;
  return (
    <button
      onClick={onClick}
      className={`dp-button-${variant} dp-button-size-${size} ${className}`}
      type={type}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};

export default Button;
