import ReactTooltip from "react-tooltip";
import React from "react";
import "./index.css";
import { AllPlanFeature } from "./TableData";
import { enabledIcon, disabledIcon, customIcon, infoIcon } from "./icons";

export const getIconTypeAndText = (
  data: boolean | string
): JSX.Element | string => {
  switch (data) {
    case true:
      return disabledIcon;
    case false:
      return enabledIcon;
    case "custom":
      return customIcon;
    default:
      return data;
  }
};

export const COLUMN_LENGTH = [0, 1, 2];
const PLAN_NAMES = ["Paid Plan", "Custom Plan"];

const PricingTable: React.FC = () => {
  const featureHeader = (title: string) => {
    return (
      <div className="flex justify-between table-header mt-6">
        <div
          className="pl-6 font-medium"
          style={{ color: "#6353EA", fontSize: 16 }}
        >
          {title}
        </div>
        <div
          className="flex justify-between space-x-2 text-black font-medium"
          style={{ fontSize: 16 }}
        >
          {PLAN_NAMES.map((name, index) => (
            <div key={index} className="w-44 text-center">
              {name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const featureRow = (item: {
    title: string;
    info: string;
    disabled?: boolean[];
    detail?: string[];
    prefixDetail?: string[];
    discount?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mixValue?: any[];
  }) => {
    return (
      <div
        className="flex justify-between items-center"
        style={{
          color: "#737D94",
          fontSize: 14,
          height: "50px",
        }}
      >
        <div className="flex pl-10">
          {item.title}
          {item.info && (
            <p
              className="ml-2.5 cursor-pointer flex align-center"
              data-text-color="white"
              data-padding="0px 8px"
              data-background-color="#B6BAC8"
              data-place="right"
              data-tip={item.info}
            >
              {infoIcon}
            </p>
          )}
        </div>
        <ReactTooltip />
        <div className="flex justify-between space-x-2 items-center h-full">
          {item?.disabled?.map((disable, index) => {
            return (
              <div
                className="flex w-44 text-center justify-center items-center"
                key={index}
              >
                {getIconTypeAndText(disable)}
              </div>
            );
          })}

          {item?.detail?.map((detail, index) => {
            return (
              <div
                className="w-44 text-center relative h-full flex justify-center align-center"
                key={index}
              >
                <div className="h-full flex align-center justify-end">
                  {item?.discount && item.discount[index] && (
                    <span
                      className="strikethrough absolute text-white top-0 rounded-sm p-0.5"
                      style={{
                        background: "#8778FF",
                        fontSize: "9px",
                      }}
                    >
                      {item.discount[index]}
                    </span>
                  )}
                  <div>
                    {item.prefixDetail && (
                      <span className="mr-0.5" style={{ fontSize: "6px" }}>
                        {item.prefixDetail[index]}
                      </span>
                    )}
                    <span>{getIconTypeAndText(detail)}</span>
                  </div>
                </div>
              </div>
            );
          })}

          {item?.mixValue?.map((value, index) => {
            return (
              <div
                className="flex w-44 text-center justify-center items-center"
                key={index}
              >
                {getIconTypeAndText(value)}
              </div>
            );
          })}
          {/* Note: to fill all empty item with enabledIcon */}
          {!item?.detail &&
            !item?.disabled &&
            !item?.mixValue &&
            COLUMN_LENGTH.map((detailIndex) => {
              return (
                <div
                  className="flex w-44 text-center justify-center"
                  key={detailIndex}
                >
                  {enabledIcon}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="hidden md:flex mt-12">
      <div
        style={{
          maxWidth: "1150px",
        }}
        className="flex sm:justify-start xl:justify-center flex-row items-stretch space-x-4 space-y-0 pb-10 mx-auto"
      >
        <section className="flex flex-col w-full p-6 py-2 space-y-2 bg-white rounded-lg shadow-card">
          {featureHeader("ฟีเจอร์ช่วยขายผ่านแชท")}
          <div>
            {AllPlanFeature.chatAndShop.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("ฟีเจอร์ช่วยจัดการระบบหลังร้าน")}
          <div>
            {AllPlanFeature.storeManagement.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การจัดส่ง")}
          <div>
            {AllPlanFeature.shipping.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การเชื่อมต่อ")}
          <div>
            {AllPlanFeature.integration.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("การเข้าใช้งานระบบหลังร้าน")}
          <div>
            {AllPlanFeature.consoleAccessibility.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("ฟีเจอร์เสริม")}
          <div>
            {AllPlanFeature.AddOn.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>

          {featureHeader("1convo pay")}
          <div>
            {AllPlanFeature.deeplePay.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>
          {featureHeader("การชำระผ่านการโอน")}
          <div>
            {AllPlanFeature.bankTransfer.map((item) => {
              return <div key={item.title}>{featureRow(item)}</div>;
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default PricingTable;
