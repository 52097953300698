import React from "react";

import "./ourClient.css";
import image0 from "../../../../static/brand/0.png";
import image1 from "../../../../static/brand/1.png";
import image2 from "../../../../static/brand/2.png";
import image3 from "../../../../static/brand/3.png";
import image4 from "../../../../static/brand/4.png";
import image5 from "../../../../static/brand/5.png";
import image6 from "../../../../static/brand/6.png";
import image7 from "../../../../static/brand/7.png";
import image8 from "../../../../static/brand/8.png";
import image11 from "../../../../static/brand/11.png";
import image12 from "../../../../static/brand/12.png";
import image14 from "../../../../static/brand/14.png";
import image15 from "../../../../static/brand/15.png";
import image17 from "../../../../static/brand/17.png";
import image19 from "../../../../static/brand/19.png";
import apaiImage from "../../../../static/brand/apai.png";
import braceImage from "../../../../static/brand/brace.png";
import doubleAImage from "../../../../static/brand/double-a.png";
import iccImage from "../../../../static/brand/icc.png";
import smlImage from "../../../../static/brand/sml.png";
import auroraImage from "../../../../static/brand/aurora.jpeg";
import btnImage from "../../../../static/brand/btn.png";
import hafeleImage from "../../../../static/brand/hafele.png";
import osakaImage from "../../../../static/brand/osaka.jpeg";
import supercatImage from "../../../../static/brand/supercat.jpeg";
import upbrainImage from "../../../../static/brand/upbrain.jpeg";

const ourClients = [
  { id: 0, imageTop: image0, imageBottom: image7 },
  { id: 1, imageTop: image1, imageBottom: image8 },
  { id: 2, imageTop: image2, imageBottom: doubleAImage },
  { id: 3, imageTop: image3, imageBottom: apaiImage },
  { id: 4, imageTop: image4, imageBottom: image11 },
  { id: 5, imageTop: image5, imageBottom: image12 },
  { id: 6, imageTop: image6, imageBottom: braceImage },
  { id: 8, imageTop: image14, imageBottom: image17 },
  { id: 7, imageTop: image15, imageBottom: iccImage },
  { id: 9, imageTop: smlImage, imageBottom: image19 },
  { id: 10, imageTop: auroraImage, imageBottom: osakaImage },
  { id: 11, imageTop: btnImage, imageBottom: supercatImage },
  { id: 12, imageTop: hafeleImage, imageBottom: upbrainImage },
  // Repeat
  { id: 0, imageTop: image0, imageBottom: image7 },
  { id: 1, imageTop: image1, imageBottom: image8 },
  { id: 2, imageTop: image2, imageBottom: doubleAImage },
  { id: 3, imageTop: image3, imageBottom: apaiImage },
  { id: 4, imageTop: image4, imageBottom: image11 },
  { id: 5, imageTop: image5, imageBottom: image12 },
  { id: 6, imageTop: image6, imageBottom: braceImage },
  { id: 8, imageTop: image14, imageBottom: image17 },
  { id: 7, imageTop: image15, imageBottom: iccImage },
  { id: 9, imageTop: smlImage, imageBottom: image19 },
  { id: 10, imageTop: auroraImage, imageBottom: osakaImage },
  { id: 11, imageTop: btnImage, imageBottom: supercatImage },
  { id: 12, imageTop: hafeleImage, imageBottom: upbrainImage },
];

const OurClient: React.FC = () => (
  <div className="relative bg-black pb-12 lg:pb-20">
    <div className="px-8 lg:px-6 pb-12 mx-auto our-client-wrapper">
      <h1
        className="video-sub-heading-2 text-white mb-1"
        style={{ width: "max-content" }}
      >
        Our Clients
      </h1>
      <h1 className="font-weight-600 text-gradient card-integration-heading">
        ร้านค้าที่เลือกใช้ 1convo
      </h1>
    </div>
    <div className="pl-8" style={{ width: "1150px", margin: "auto" }}>
      <div className="slide-option">
        <div id="infinite" className="highway-slider">
          <div className="containers highway-barrier">
            <ul className="highway-lane">
              <div className="dp-cards-scroller-item-view-our-client">
                {ourClients.map((item) => (
                  <li key={item.id} className={`highway-car pr-5 pb-5 `}>
                    <div className="content-style">
                      <img src={item.imageTop} style={{ maxWidth: "145px" }} />
                      <img
                        src={item.imageBottom}
                        style={{ maxWidth: "145px" }}
                      />
                    </div>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurClient;
