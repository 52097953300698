import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "../index.css";
import LottiePlayer from "../../LottiePlayer";

import ScanQR from "../../../../static/lottie/04-2-Scan-QR.json";

type CardPropsType = {
  children?: React.ReactNode;
};

const cardDetails = [
  {
    text1: "Mobile Banking",
    text2: "จ่ายผ่านแอปฯ ธนาคารอย่างไร้รอยต่อ",
    text2Color: "text-black",
    videoLink: null,
    content: (
      <StaticImage
        className="absolute bottom-0"
        alt="Mobile Banking"
        src={"../../../../static/image/banking.png"}
        height={310}
        width={360}
        placeholder="blurred"
      />
    ),
  },
  {
    text1: "QR Code",
    text2: "สแกนแล้วจ่ายได้ทันที",
    text2Color: "text-white",
    content: (
      <LottiePlayer
        file={ScanQR}
        height={"auto"}
        width={"100%"}
        isDarkBackground={true}
      />
    ),
  },
  {
    text1: "Debit / Credit Card",
    text2: "ชำระเงินผ่านบัตรเครดิต หรือเดบิต",
    text2Color: "text-black",
    videoLink: null,
    content: (
      <StaticImage
        className="absolute bottom-0"
        alt="Debit / Credit Card"
        src="../../../../static/image/credit-card.png"
        height={310}
        width={360}
        placeholder="blurred"
      />
    ),
  },
  {
    text1: "E-Wallet",
    text2: "เติม-จ่ายผ่านวอลเล็ต",
    text2Color: "text-black",
    videoLink: null,
    content: (
      <StaticImage
        className="absolute bottom-0"
        alt="E-Wallet"
        src="../../../../static/image/rabbit-line-pay.png"
        height={310}
        width={360}
        placeholder="blurred"
      />
    ),
  },
];

const CardScrollerDeeplePay: React.FC<CardPropsType> = () => {
  return (
    <div className="relative">
      <div className="card-scroller-container p-6">
        <div>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            จ่ายเงินสะดวก&nbsp;
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            ผ่านช่องทางที่ลูกค้าเลือกเอง
          </span>
        </div>
      </div>
      <div className="dp-cards-scroller">
        <div className="dp-cards-scroller-crop">
          <div className="dp-cards-scroller-content">
            <div className="dp-cards-scroller-platter">
              {cardDetails.map((card) => {
                return (
                  <div className="dp-cards-scroller-item-view" key={card.text1}>
                    <div className="dp-card-width-360">
                      <div className="dp-card-content">
                        <div className="dp-card-img-full-wrapper">
                          {card.content}
                        </div>
                        <div className="dp-card-content-info">
                          <h3 className="dp-card-content-eyebrow">
                            {card.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <a
                              className={`dp-card-content-header-link ${card.text2Color}`}
                            >
                              {card.text2}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrollerDeeplePay;
