import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import "./index.css";

type CardPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file?: any;
  width: number | string;
  height: number | string;
  isDarkBackground?: boolean;
  payButtonClassName?: string;
  lottieClassName?: string;
  defaultPlay?: boolean;
  controllable?: boolean;
};

const LottiePlayer: React.FC<CardPropsType> = ({
  file = null,
  width = 320,
  height = 400,
  isDarkBackground = false,
  payButtonClassName = "mr-4 mb-4",
  lottieClassName = "",
  defaultPlay = false,
  controllable = true,
}) => {
  const [play, setPlay] = useState(defaultPlay);

  return (
    <div className="relative">
      <div
        onMouseEnter={() => {
          controllable && setPlay(true);
        }}
        onMouseLeave={() => {
          controllable && setPlay(false);
        }}
        onClick={() => controllable && setPlay(!play)}
      >
        <div className={lottieClassName}>
          <Lottie
            options={{
              loop: true,
              autoplay: defaultPlay,
              animationData: file,
            }}
            isStopped={!play}
            height={height}
            width={width}
            className="pb-10"
          />
        </div>
        {controllable && (
          <button
            className={`absolute bottom-0 right-0 ${payButtonClassName} ${
              play ? "pause" : "play"
            }Button${isDarkBackground ? "-dark" : ""}`}
            type="button"
            onClick={() => controllable && setPlay(!play)}
          />
        )}
      </div>
    </div>
  );
};

export default LottiePlayer;
