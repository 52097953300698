import React from "react";
import "../HomeBanner/index.css";

import Lottie from "react-lottie";
import Card from "../Card";
import Button from "../Button";

import ConversationStart from "../../../static/lottie/01-1-deeple-ai-chatbot-homepage.json";

const CampaignBanner: React.FC = () => (
  <div className="home-banner">
    <div className="section-container center-container grid sm:grid-cols-1 md:grid-cols-2 px-6">
      <div className="pt-6">
        <h1 className="dashboard-heading-1 my-4 text-white font-weight-600">
          1convo
        </h1>

        <h2 className="dashboard-heading-2 my-4 pt-2 text-white font-weight-600">
          ระบบจัดการร้านค้าออนไลน์ครบวงจร ด้วยเทคโนโลยี AI ที่รู้ใจทุกธุรกิจบน
          Social Commerce
        </h2>

        <h2 className="dashboard-heading-3 my-4 text-white">
          ใช้งานบน LINE, Facebook และ Instagram
        </h2>

        <div
          style={{ display: "flex" }}
          className="mt-6 items-center md:hidden"
        >
          <a href="https://console.1convo.ai/register">
            <Button className="px-10" variant="dark" size="medium">
              สมัครใช้งานวันนี้
            </Button>
          </a>
        </div>
        <div
          className="flex justify-content-center mt-6 overflow-x-scroll min-w-full"
          style={{ width: "360px" }}
        >
          <Card
            size="small"
            className="dashboard-card mr-3 dashboard-heading-4 text-center"
          >
            ปิดการขาย
            <br />
            แทนแอดมิน
            <br />
            <h1 className="dashboard-heading-1 my-2">90%</h1>
            ของจำนวนออเดอร์
          </Card>
          <Card
            size="small"
            className="dashboard-card mr-3 dashboard-heading-4 text-center"
          >
            ช่วยลด
            <br />
            งานแอดมินมากถึง
            <br />
            <h1 className="dashboard-heading-1 my-2">85%</h1>
            ของการซื้อขาย
          </Card>
          <Card
            size="small"
            className="dashboard-card mr-3 dashboard-heading-4 text-center"
          >
            เชื่อมต่อร้านค้า
            <br />
            ไปแล้วมากกว่า
            <br />
            <h1 className="dashboard-heading-1 my-2">1K+</h1>
            ร้านค้า
          </Card>
        </div>
        <div className="hidden md:flex mt-6 items-center">
          <a href="https://console.1convo.ai/register">
            <Button className="px-10" variant="dark" size="medium">
              สมัครใช้งานวันนี้
            </Button>
          </a>
        </div>
      </div>

      <div className="phone-mockup">
        <div className="dashboardImage" />
        <div className="phone-wrapper mt-12">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: ConversationStart,
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default CampaignBanner;
