import React from "react";
import "../../CardScroller/index.css";

import LottiePlayer from "../../LottiePlayer";

type CardPropsType = {
  children?: React.ReactNode;
  card: {
    headerText1: string;
    headerText2: string;
    headerText3: string;
    cardDetails: {
      text1?: string;
      text2: string;
      videoLink?: unknown;
      isDarkBackgroundVideoIcon?: boolean;
      image?: string;
      fullImageBg?: string;
      background: string;
      textColor: string;
      marginBottom?: string;
      lottieClassName?: string;
    }[];
  };
};

const CardIntegration: React.FC<CardPropsType> = ({ card }) => {
  // TODO: trying to fix drag to scroll so will remove this if it's not working in next release
  // const ele = document.getElementById("db-card-mouse-scroller");
  // if (ele) {
  //   ele.scrollTop = 100;
  //   ele.scrollLeft = 150;
  // }
  // let pos = { top: 0, left: 0, x: 0, y: 0 };

  // const mouseDownHandler = function (e) {
  //   pos = {
  //     // The current scroll
  //     left: ele?.scrollLeft || 0,
  //     top: ele?.scrollTop || 0,
  //     // Get the current mouse position
  //     x: e.clientX,
  //     y: e.clientY,
  //   };

  //   document.addEventListener("mousemove", mouseMoveHandler);
  //   document.addEventListener("mouseup", mouseUpHandler);
  // };
  // const mouseMoveHandler = function (e) {
  //   console.log("move", ele);
  //   // How far the mouse has been moved
  //   const dx = e.clientX - pos.x;
  //   const dy = e.clientY - pos.y;
  //   if (ele) {
  //     // Scroll the element
  //     ele.scrollTop = pos.top - dy;
  //     ele.scrollLeft = pos.left - dx;
  //   }
  // };
  // const mouseUpHandler = function () {
  //   document.removeEventListener("mousemove", mouseMoveHandler);
  //   document.removeEventListener("mouseup", mouseUpHandler);
  //   if (ele) {
  //     ele.style.cursor = "grab";
  //     ele.style.removeProperty("user-select");
  //   }
  // };
  // const handleMouseDown = ele ? mouseDownHandler : () => {};
  return (
    <div className="pb-8 lg:pb-0 relative bg-white card-integration-wrapper-new-online">
      <div className="card-scroller-container pb-0 pb-12 p-6 px-8 pt-3 lg:px-0">
        <div>
          <h2 className="video-sub-heading-2 mb-1">{card.headerText1}</h2>
          <h3 className="font-weight-600 text-gradient card-integration-heading pb-2 w-fit-content">
            {card.headerText2}
          </h3>
          <h3 className="card-integration-heading">{card.headerText3}</h3>
        </div>
      </div>

      <div className="dp-cards-scroller lg:pb-12">
        <div className="dp-cards-scroller-crop" id="db-card-mouse-scroller">
          <div className="dp-cards-scroller-content db-card-mouse-scroller">
            <div className="dp-cards-scroller-platter">
              {card.cardDetails.map((detail) => {
                return (
                  <div
                    className="dp-cards-scroller-item-view"
                    key={detail.text1}
                  >
                    <div className="dp-card-width-360">
                      <div
                        className={`dp-card-content ${
                          detail?.background || "bg-white"
                        }`}
                      >
                        <div className="dp-card-img-full-wrapper">
                          {detail?.fullImageBg && (
                            <div
                              className="w-full h-full absolute z-5 bg-contain"
                              style={{
                                backgroundImage:
                                  "url(" + detail.fullImageBg + ")",
                              }}
                            />
                          )}
                          <div className="absolute bottom-0 w-full">
                            {detail.videoLink && (
                              <LottiePlayer
                                key={detail.text1}
                                file={detail.videoLink}
                                height={"auto"}
                                width={"100%"}
                                lottieClassName={
                                  detail.lottieClassName || "pb-10"
                                }
                                isDarkBackground={
                                  detail.isDarkBackgroundVideoIcon || false
                                }
                              />
                            )}
                            {detail.image && (
                              <img
                                className={`${detail.marginBottom || "mb-0"}`}
                                alt={detail.text2}
                                key={detail.text1}
                                src={detail.image}
                                height={310}
                                width={360}
                              />
                            )}
                          </div>
                        </div>
                        <div className="dp-card-content-info">
                          <h3 className="dp-card-content-eyebrow">
                            {detail.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <a
                              className={`dp-card-content-header-link z-10 ${
                                detail?.textColor || "text-black"
                              }`}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: detail.text2,
                                }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIntegration;
