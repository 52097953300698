import React from "react";

interface GridContainer {
  headerClassName?: string;
}

const GridContainer: React.FC<GridContainer> = ({
  children,
  headerClassName,
}) => {
  return (
    <div className={`header-container ${headerClassName}`}>
      <div className="guides">
        <div className="w-layout-grid guides-container">
          <div className="guide-line-1"></div>
          <div className="guide-line-2"></div>
          <div className="guide-line-3"></div>
          <div className="guide-line-4"></div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default GridContainer;
