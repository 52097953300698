import { PageProps } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import { structuredData } from "../configs";

interface WithPagesProps extends PageProps {
  title?: string;
  description?: string;
  linkImage?: string;
  linkHeaderTags?: { rel: string; href: string; id: number }[];
}

const withPages =
  (Component: React.FC) =>
  ({
    title,
    description,
    linkHeaderTags = [],
    linkImage,
    ...props
  }: WithPagesProps) => {
    React.useEffect(() => {
      new URLSearchParams(props.location.search).forEach((value, key) => {
        window.localStorage.setItem(key, value);
      });
    });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>

          {/* Image from gatsby-node.js example, using gatsby-image for override */}
          <meta
            property="og:image"
            content={
              linkImage ||
              "https://storage.googleapis.com/deeple-landing-page/images/website-cover.png"
            }
          />
          {linkHeaderTags.map((item) => (
            <link rel={item.rel} href={item.href} key={item.id} />
          ))}
        </Helmet>
        <Component {...props} />
      </>
    );
  };

export default withPages;
