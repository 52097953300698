import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./index.css";

import TopNav from "../TopNav";

import TryFreeTrial from "../TryFreeTrial";

import Footer from "../Footer";
import Plan from "./Plan";
import PricingTable from "./PricingTable";
import PricingTableMobile from "./PricingTableMobile";
import GridContainer from "../GridContainer";

const Pricing: React.FC = () => {
  const [activePlanSlide, setActivePlanSlide] = useState(0);
  return (
    <>
      <StaticImage
        src={"../../../static/image/deeple-store-management-bg.jpg"}
        alt="Header background"
        loading="eager"
        placeholder="blurred"
        className="absolute header-pink-home"
      />
      <div className="center-container">
        <TopNav isWhiteTheme />
      </div>

      <div className="header-container">
        <div className="mt-16 relative">
          <Plan handleChangeSlidePlan={setActivePlanSlide} />
        </div>
        <PricingTable />
        <PricingTableMobile showIndex={activePlanSlide} />
      </div>

      <GridContainer>
        <TryFreeTrial />
      </GridContainer>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Pricing;
