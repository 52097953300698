import React from "react";
import { navigate } from "gatsby";
import axios from "axios";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";
import { Controller, useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { StaticImage } from "gatsby-plugin-image";
import Select from "react-select";

import TopNav from "../TopNav";
import Footer from "../Footer";
import Button from "../Button";
import { PURPOSE_OPTIONS } from "./constants";
import type { PurposeOption } from "./constants";

type ContactFormField = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  purpose: PurposeOption;
};

const Contact: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm<ContactFormField>();

  const handleVerify = async (token: string) => {
    const value = getValues();

    clearErrors("email");

    try {
      await axios.post(
        "/api/hubspot",
        {
          firstname: value.firstName,
          lastname: value.lastName,
          mobilephone: value.phoneNumber,
          email: value.email,
          purpose: value.purpose.value,
          utm_campaign: window.localStorage.getItem("utm_campaign"),
          utm_content: window.localStorage.getItem("utm_content"),
          utm_medium: window.localStorage.getItem("utm_medium"),
          utm_source: window.localStorage.getItem("utm_source"),
          utm_term: window.localStorage.getItem("utm_term"),
        },
        {
          headers: {
            "g-recaptcha": token,
          },
        }
      );

      navigate("/thank-you");
    } catch (err) {
      setError("email", {
        type: "manual",
        message: "อีเมลนี้เคยลงทะเบียนแล้ว",
      });

      setSubmitted(false);
    }
  };

  const onSubmit = handleSubmit(
    () => setSubmitted(true),
    (errors) => console.log("errors", errors)
  );

  return (
    <>
      <StaticImage
        src={"../../../static/image/deeple-store-management-bg.jpg"}
        alt="Header background"
        loading="eager"
        placeholder="blurred"
        className="absolute header-pink-home"
      />
      <ReactTooltip className="px-2 text-xs" />

      <div className="center-container">
        <TopNav isWhiteTheme />
      </div>

      <div className="hidden">
        <ReCaptcha
          action="homepage"
          siteKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          onVerify={handleVerify}
          submitted={submitted}
        />
      </div>

      <div className="header-container ">
        <div className="guides">
          <div className="w-layout-grid guides-container">
            <div className="guide-line-1" />
            <div className="guide-line-2" />
            <div className="guide-line-3" />
            <div className="guide-line-4" />
          </div>
        </div>

        <div className="center-container mb-24 md:mb-0 mt-8 w-5/6 lg:3/5 xl:w-4/6 relative max-w-[720px]">
          <div className="grid grid-cols-1">
            <div className="flex flex-col items-center pb-16">
              <h1 className="text-2xl md:text-3xl text-black mb-8">
                Contact our sales team
              </h1>

              <p className="text-black w-96 md:w-full text-center">
                เรามีทีมผู้เชี่ยวชาญพร้อมให้คำปรึกษา เพื่อให้ 1convo
                ตอบโจทย์ธุรกิจของคุณได้จริง
              </p>
            </div>

            <form onSubmit={onSubmit}>
              <div
                className="grid grid-cols-2 gap-x-8 gap-y-4 bg-white rounded-lg px-8 md:px-12 py-8 pb-12"
                style={{
                  boxShadow: "-11px 3px 20px 6px rgb(204 216 255 / 20%)",
                }}
              >
                <div className="col-span-2 text-center mb-6">
                  <span className="text-2xl md:text-3xl">
                    ลงทะเบียนติดต่อเรา
                  </span>
                </div>

                {/* first name */}
                <div className="h-20 col-span-2 md:col-span-1">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="firstName"
                  >
                    ชื่อ
                  </label>
                  <input
                    id="firstName"
                    placeholder="ชื่อ"
                    className="appearance-none border rounded w-full py-2 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline font-graphikthloop"
                    {...register("firstName", { required: true })}
                  />
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.firstName?.type === "required" && "กรุณากรอกชื่อ"}
                  </p>
                </div>

                {/* last name */}
                <div className="h-20 col-span-2 md:col-span-1">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="lastName"
                  >
                    นามสกุล
                  </label>
                  <input
                    id="lastName"
                    placeholder="นามสกุล"
                    className="appearance-none border rounded w-full py-2 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline font-graphikthloop"
                    {...register("lastName", { required: true })}
                  />
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.lastName?.type === "required" && "กรุณากรอกนามสกุล"}
                  </p>
                </div>

                {/* phone number */}
                <div className="h-20 col-span-2 md:col-span-1">
                  <label
                    className="flex items-center text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phone-number"
                  >
                    <p className="pr-1">เบอร์มือถือ</p>
                    <a
                      className="flex justify-center items-center text-white rounded-full bg-gray-500 w-4 h-4 text-xs font-graphikthloop"
                      data-place="right"
                      data-effect="solid"
                      data-padding="0px"
                      data-tip="กรอกตัวเลข 10 หลัก"
                      data-background-color="#AAAAAA"
                      data-arrow-color="#AAAAAA"
                    >
                      ?
                    </a>
                  </label>

                  <input
                    id="phoneNumber"
                    placeholder="ตัวอย่าง 0801234567"
                    className="appearance-none border rounded w-full py-2 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline font-graphikthloop"
                    {...register("phoneNumber", {
                      required: true,
                      pattern: /\d{10}/,
                    })}
                  />
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.phoneNumber?.type === "required" &&
                      "กรุณากรอกตัวเลข 10 หลัก"}
                  </p>
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.phoneNumber?.type === "pattern" &&
                      "กรุณากรอกตัวเลข 10 หลัก"}
                  </p>
                </div>

                {/* email */}
                <div className="h-20 col-span-2 md:col-span-1">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    อีเมล
                  </label>
                  <input
                    id="email"
                    type="text"
                    placeholder="อีเมล"
                    className="appearance-none border rounded w-full py-2 px-3 text-sm leading-tight focus:outline-none focus:shadow-outline font-graphikthloop"
                    {...register("email", {
                      required: true,
                      pattern: /\S+@\S+\.\S+/,
                    })}
                  />
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.email?.type === "required" && "กรุณากรอกอีเมล"}
                  </p>
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.email?.type === "pattern" &&
                      "กรุณากรอกอีเมลให้ถูกต้อง"}
                  </p>
                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.email?.type === "manual" && errors.email?.message}
                  </p>
                </div>

                {/* purpose */}
                <div className="h-20 col-span-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="purpose"
                  >
                    ต้องการใช้ 1convo ตอบโจทย์ธุรกิจด้านใดมากที่สุด
                  </label>
                  <Controller
                    name="purpose"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        name={field.name}
                        ref={field.ref}
                        onBlur={(e) => {
                          e.stopPropagation();
                          field.onBlur();
                        }}
                        onChange={(newValue) => field.onChange(newValue)}
                        value={field.value}
                        id="purpose"
                        options={PURPOSE_OPTIONS}
                        className="appearance-none border rounded w-full text-sm leading-tight focus:outline-none focus:shadow-outline font-graphikthloop"
                        styles={{
                          control: (currentStyle) => ({
                            ...currentStyle,
                            border: "none !important",
                          }),
                          placeholder: (currentStyle) => ({
                            ...currentStyle,
                            color: "rgb(157 162 173) !important",
                            fontFamily: "graphikthloop !important",
                            fontWeight: "400 !important",
                            fontSize: "0.875rem !important",
                            lineHeight: "1.25rem !important",
                          }),
                          singleValue: (currentStyle) => ({
                            ...currentStyle,
                            border: "none !important",
                            fontSize: "14px",
                            fontFamily: "graphikthloop !important",
                            fontWeight: "400 !important",
                            color: "rgb(55, 65, 81)",
                          }),
                          option: (currentStyle) => ({
                            ...currentStyle,
                            fontSize: "14px",
                            fontFamily: "graphikthloop !important",
                            fontWeight: "400 !important",
                            color: "rgb(55, 65, 81)",
                          }),
                        }}
                        placeholder="เลือกความต้องการด้านธุรกิจ"
                      />
                    )}
                  />

                  <p className="pt-1 font-graphikthloop text-xs text-red-600">
                    {errors.purpose?.["type"] === "required" &&
                      "กรุณาเลือกความต้องการด้านธุรกิจ"}
                  </p>
                </div>

                {/* submit */}
                <div className="col-span-2 text-center mt-2">
                  <Button
                    type="submit"
                    className="px-8 py-2"
                    size="medium"
                    variant="primary"
                  >
                    ลงทะเบียน
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};
export default Contact;
