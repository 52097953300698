import React from "react";

import TopNav from "../../TopNav";

import CardVideo from "./CardVideo";
import CardIphone from "./CardIphone";

import CardIntegration from "./CardIntegration";

import CampaignBanner from "../../CampaignBanner/New-online";

import TryFreeTrial from "../../TryFreeTrial/New-online";

import Footer from "../../Footer";
import FeatureList from "./FeatureList";
import OurClient from "./OurClient";
import Testimonials from "./Testimonials";
import GridContainer from "../../GridContainer";
import LogisticsProvider from "../../../../static/lottie/05-2-Logistics-Provider.json";
import CreditCardPaymentImage from "../../../../static/image/campaign/credit-card-payment.png";
import ZortImage from "../../../../static/image/campaign/zort.png";
import openAPIImage from "../../../../static/image/campaign/open-api.png";
import Goods1Image from "../../../../static/image/campaign/goods-campaign-1.png";
import Goods2Image from "../../../../static/image/campaign/goods-2.png";
import Goods3Image from "../../../../static/image/campaign/goods-campaign-3.png";
import Goods4Image from "../../../../static/image/campaign/goods-4.png";
import Goods5Image from "../../../../static/image/campaign/goods-5.png";
import HeaderImage from "../../../../static/image/deeple-store-management-bg.jpg";
import CardImage from "./CardImage";
import Pricing from "./Pricing";

const CampaignChatbot: React.FC = () => (
  <>
    <div
      className="absolute header-pink-home-campaign"
      style={{
        backgroundImage: "url(" + HeaderImage + ")",
      }}
    />
    <div className="center-container pb-5 lg:pb-0">
      <TopNav isWhiteTheme />
    </div>

    <CampaignBanner />

    <CardVideo />

    <GridContainer>
      <CardIntegration
        card={{
          headerText1: "1convo, Solution for All Business Types",
          headerText2: "เพิ่มทางเลือกการขายออนไลน์",
          headerText3: "เพื่อหลากหลายประเภทธุรกิจ",
          cardDetails: [
            {
              text2: "สินค้าทั่วไป สินค้าสำเร็จรูป",
              fullImageBg: Goods1Image,
              background: "",
              textColor: "text-white",
              marginBottom: "mb-0",
            },
            {
              text2: "สินค้าที่ต้องปรึกษาเชิงลึก",
              videoLink: null,
              image: Goods2Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
            },
            {
              text2: "สินค้าพรีออเดอร์ ขายส่ง",
              videoLink: null,
              fullImageBg: Goods3Image,
              background: "",
              textColor: "text-white",
              marginBottom: "mb-0",
            },
            {
              text2: "สินค้าที่มีการออกแบบเฉพาะ <br>(Custom Products)",
              videoLink: null,
              image: Goods4Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
            },
            {
              text2: "คูปอง บัตรเติมเงิน บัตรของขวัญ",
              videoLink: null,
              image: Goods5Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-5",
            },
          ],
        }}
      />
    </GridContainer>

    <CardImage />

    <CardIphone />

    <GridContainer>
      <CardIntegration
        card={{
          headerText1: "1convo Platform Integrations",
          headerText2: "เชื่อมต่อบริการด้าน E-commerce ครบวงจร",
          headerText3: "ให้ทุกการขายไม่สะดุด",
          cardDetails: [
            {
              text1: "Logistics Provider & COD",
              text2: "ขนส่งชั้นนำและเก็บเงินปลายทาง",
              videoLink: LogisticsProvider,
              background: "",
              textColor: "",
            },
            {
              text1: "Payment Gateway",
              text2: "ชำระเงินผ่านหลายช่องทาง",
              videoLink: null,
              image: CreditCardPaymentImage,
              background: "bg-black",
              textColor: "text-white",
              marginBottom: "mb-5",
            },
            {
              text1: "ZORT",
              text2: "ระบบจัดการออเดอร์และสต็อก",
              videoLink: null,
              image: ZortImage,
              background: "bg-custom-dark-blue",
              textColor: "text-white",
              marginBottom: "mb-0",
            },
            {
              text1: "Open API",
              text2: "API สำหรับจัดการร้านค้าที่คุณต้องการ",
              videoLink: null,
              image: openAPIImage,
              background: "bg-black",
              textColor: "text-white",
              marginBottom: "mb-13",
            },
          ],
        }}
      />
    </GridContainer>

    <GridContainer>
      <OurClient />
    </GridContainer>

    <Testimonials />

    <GridContainer>
      <FeatureList />
    </GridContainer>

    <GridContainer>
      <Pricing />
    </GridContainer>

    <GridContainer>
      <TryFreeTrial />
    </GridContainer>

    <div>
      <Footer />
    </div>
  </>
);

export default CampaignChatbot;
