import { PageProps } from "gatsby";
import Home from "../components/Home";
import withPages from "../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(Home)({
    title:
      "1convo ระบบจัดการร้านค้าออนไลน์ครบวงจร ด้วยเทคโนโลยี AI ที่รู้ใจทุกธุรกิจบน Social Commerce",
    description:
      "ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์ ดูดคอมเมนต์ไลฟ์ และช่วยขายครบวงจร สำหรับธุรกิจบน LINE, Facebook และ Instagram",
    linkHeaderTags: [
      {
        rel: "canonical",
        href: "https://magnita.ai/",
        id: 0,
      },
    ],
    ...props,
  });
}

export default IndexPage;
