import { PageProps } from "gatsby";
import CampaignChatbot from "../../../components/CampaignChatbot";
import withPages from "../../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(CampaignChatbot)({
    title:
      "ระบบจัดการร้านค้าออนไลน์ครบวงจร ด้วยเทคโนโลยี AI ที่รู้ใจทุกธุรกิจบน Social Commerce",
    description:
      "ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์ ดูดคอมเมนต์ไลฟ์ และช่วยขายครบวงจร สำหรับธุรกิจบน LINE, Facebook และ Instagram",
    ...props,
  });
}

export default IndexPage;
