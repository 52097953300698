import React from "react";
import "./index.css";

import Lottie from "react-lottie";
import Card from "../../Card";
import Button from "../../Button";

import ConversationStart from "../../../../static/lottie/01-1-deeple-ai-chatbot-homepage-new-online.json";

const cards = [
  {
    children: (
      <>
        ปิดการขาย
        <br />
        แทนแอดมิน
        <br />
        <h1 className="dashboard-heading-1 my-2">90%</h1>
        ของจำนวนออเดอร์
      </>
    ),
  },
  {
    children: (
      <>
        ช่วยลด
        <br />
        งานแอดมินมากถึง
        <br />
        <h1 className="dashboard-heading-1 my-2">85%</h1>
        ของการซื้อขาย
      </>
    ),
  },
  {
    children: (
      <>
        เชื่อมต่อร้านค้า
        <br />
        ไปแล้วมากกว่า
        <br />
        <h1 className="dashboard-heading-1 my-2">1K+</h1>
        ร้านค้า
      </>
    ),
  },
];

const HomeBanner: React.FC = () => (
  <div className="home-banner bg-white">
    <div className="section-container center-container center-container-campaign pt-0">
      <div className="flex align-center">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="relative mx-4 md:mx-11 lg:mx-0">
              <div className="absolute h-full bg-header-campaign" />
              <div className="relative header">
                <div className="card-scroller-container">
                  <div className="home-heading-new-online text-black font-weight-700">
                    ระบบจัดการร้านค้าออนไลน์ครบวงจร ด้วยเทคโนโลยี
                    <span className="text-gradient"> AI </span>
                    ที่รู้ใจทุกธุรกิจบน{" "}
                    <span className="text-gradient">Social Commerce</span>
                  </div>

                  <div className="dashboard-heading-3 my-4 text-black">
                    ใช้งานบน Facebook, LINE OA และ Instagram
                  </div>
                </div>
                <div className="flex mt-0 md:mt-9 items-center">
                  <a href="https://console.1convo.ai/register">
                    <Button
                      className="p-4 text-sm md:text-xl px-6 md:px-10"
                      variant="gradient"
                      size="small"
                    >
                      สมัครใช้งานวันนี้
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="phone-mockup-campaign">
            <div className="dashboard-image-campaign" />
            <div className="phone-wrapper-campaign mt-12">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: ConversationStart,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeBanner;
