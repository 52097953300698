import React from "react";
import "./index.css";

import Lottie from "react-lottie";
import Card from "../Card";
import Button from "../Button";

import ConversationStart from "../../../static/lottie/Header-Homepage-Iphone.json";

const cards = [
  {
    children: (
      <>
        ปิดการขาย
        <br />
        แทนแอดมิน
        <br />
        <h1 className="dashboard-heading-1 my-2">90%</h1>
        ของจำนวนออเดอร์
      </>
    ),
  },
  {
    children: (
      <>
        ช่วยลด
        <br />
        งานแอดมินมากถึง
        <br />
        <h1 className="dashboard-heading-1 my-2">85%</h1>
        ของการซื้อขาย
      </>
    ),
  },
  {
    children: (
      <>
        เชื่อมต่อร้านค้า
        <br />
        ไปแล้วมากกว่า
        <br />
        <h1 className="dashboard-heading-1 my-2">1K+</h1>
        ร้านค้า
      </>
    ),
  },
];
const CardRenderer = () => {
  return cards.map((data, index) => (
    <Card
      key={index}
      size="small"
      className="dashboard-card mr-3 dashboard-heading-4 text-center"
    >
      {data.children}
    </Card>
  ));
};

const HomeBanner: React.FC = () => (
  <div className="home-banner">
    <div className="section-container center-container pt-0">
      <div className="flex align-center">
        <div className="grid sm:grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="relative">
              <div className="absolute h-full bg-header" />
              <div className="relative header">
                <div className="card-scroller-container">
                  <div className="home-heading my-4 pt-2 text-black font-weight-700">
                    ระบบจัดการร้านค้าออนไลน์ครบวงจร ด้วยเทคโนโลยี
                    <span className="text-gradient"> AI </span>
                    ที่รู้ใจทุกธุรกิจบน{" "}
                    <span className="text-gradient">Social Commerce</span>
                  </div>

                  <div className="dashboard-heading-3 my-4 text-black">
                    ใช้งานบน LINE, Facebook และ Instagram
                  </div>
                </div>
                <div className="flex mt-0 md:mt-9 items-center">
                  <a href="https://console.1convo.ai/register">
                    <Button
                      className="p-4 text-xl px-10"
                      variant="dark"
                      size="medium"
                    >
                      สมัครใช้งานวันนี้
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="phone-mockup">
            <div className="dashboardImage" />
            <div className="phone-wrapper phone-wrapper-left mt-12">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: ConversationStart,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeBanner;
