const structuredData = {
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "1Convo",
  alternateName: "วันคอนโว",
  url: "https://magnita.ai",
  logo: "https://console.magnita.ai/assets/logo/1convo-logo-black.png",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "02-111-0319",
    contactType: "customer service",
    areaServed: "TH",
    email: "contact@magnita.ai",
    availableLanguage: ["Thai", "en"],
  },
  sameAs: [
    "https://www.facebook.com/kandadigital",
    // "https://www.youtube.com/deeplethailand",
    // "https://www.linkedin.com/company/deepleai",
  ],
};

export default structuredData;
