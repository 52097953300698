exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-campaign-deeple-ai-chatbot-facebook-index-tsx": () => import("./../../../src/pages/campaign/deeple-ai-chatbot-facebook/index.tsx" /* webpackChunkName: "component---src-pages-campaign-deeple-ai-chatbot-facebook-index-tsx" */),
  "component---src-pages-campaign-deeple-ai-chatbot-line-index-tsx": () => import("./../../../src/pages/campaign/deeple-ai-chatbot-line/index.tsx" /* webpackChunkName: "component---src-pages-campaign-deeple-ai-chatbot-line-index-tsx" */),
  "component---src-pages-campaign-deeple-ai-แชทบอท-index-tsx": () => import("./../../../src/pages/campaign/deeple-ai-แชทบอท/index.tsx" /* webpackChunkName: "component---src-pages-campaign-deeple-ai-แชทบอท-index-tsx" */),
  "component---src-pages-campaign-deeple-ai-แชทบอท-ระบบจัดการร้านค้าออนไลน์-index-tsx": () => import("./../../../src/pages/campaign/deeple-ai-แชทบอท-ระบบจัดการร้านค้าออนไลน์/index.tsx" /* webpackChunkName: "component---src-pages-campaign-deeple-ai-แชทบอท-ระบบจัดการร้านค้าออนไลน์-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-index-tsx": () => import("./../../../src/pages/platform/index.tsx" /* webpackChunkName: "component---src-pages-platform-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-term-and-service-index-tsx": () => import("./../../../src/pages/term-and-service/index.tsx" /* webpackChunkName: "component---src-pages-term-and-service-index-tsx" */),
  "component---src-pages-thank-you-index-tsx": () => import("./../../../src/pages/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-thank-you-index-tsx" */)
}

