import { PageProps } from "gatsby";

import CampaignChatbot from "../../../components/CampaignChatbot/Facebook/index";
import withPages from "../../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(CampaignChatbot)({
    title:
      "1convo ระบบจัดการร้านค้าออนไลน์ครบวงจรที่รู้ใจทุกธุรกิจ ใช้งานบน Facebook และ IG",
    description:
      "ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์ ดูดคอมเมนต์ไลฟ์ และช่วยขายครบวงจร สำหรับธุรกิจบน Facebook, Instagram และ LINE OA",
    ...props,
  });
}

export default IndexPage;
