import React from "react";

import Button from "../../Button";

import BGHeaderImage from "../../../../static/image/BGHeader.png";
import CorrectIcon from "../../../../static/image/correctIcon.svg";

const FeatureList: React.FC = () => (
  <div
    className="relative bg-cover my-0"
    style={{
      paddingTop: "60px",
      paddingBottom: "60px",
      backgroundImage: "url(" + BGHeaderImage + ")",
      backgroundPosition: "50% 100%",
    }}
  >
    <div className="flex  justify-center text-white flex-col pl-8 sm:pl-2 sm:justify-start items-start sm:items-center">
      <h1 className="pb-1 text-3xl">
        เริ่มต้นใช้งานระบบจัดการร้านค้าออนไลน์ 1convo ผ่าน LINE OA
      </h1>
      <h1 className="text-xl">ทดลองใช้งานฟรี</h1>
    </div>

    <div
      style={{
        maxWidth: "1150px",
      }}
      className="relative mx-auto"
    >
      <div
        className="grid 
                        grid-cols-1 sm:justify-start md:grid-cols-2 lg:grid-cols-4 gap-4
                        pl-8 sm:pl-12 md:pl-8"
      >
        <div className="mt-12 md:mt-8 text-white text-xl md:text-md">
          <img
            src={CorrectIcon}
            loading="lazy"
            height="60"
            width="40"
            alt=""
            className="mb-4"
          />

          <h3 className="pb-4">ฟีเจอร์ซื้อ-ขายผ่านแชท</h3>
          <p>
            • เลือกวิธีซื้อผ่านแชท หรือเว็บช็อปปิ้ง
            <br />
            • AI ช่วยกรอกที่อยู่อัตโนมัติ
            <br />
            • AI เสนอโปรโมชันอัตโนมัติ
            <br />
            • สอน AI ตอบคำถามเพิ่ม 30 คำถาม
            <br />
            • ระบบดูดคอมเมนต์จาก Facebook Live
            <br />
          </p>
        </div>
        <div className="mt-12 md:mt-8 text-white text-xl md:text-md">
          <img
            src={CorrectIcon}
            loading="lazy"
            height="60"
            width="40"
            alt=""
            className="mb-4"
          />
          <h3 className="pb-4">
            ระบบจัดการร้านค้า
            <br />
          </h3>
          <p>
            • รวมแชทจาก LINE, Facebook และ IG
            <br />
            • ระบบช่วยจัดการออเดอร์และสต็อก
            <br />
            • รายงานการขายและพฤติกรรมลูกค้า
            <br />
            • บรอดแคสต์ผ่าน LINE OA
            <br />
            • ระบบจัดกลุ่มลูกค้าตามความสนใจ
            <br />
            • ระบบจัดการธีมร้าน
            <br />
          </p>
        </div>
        <div className="mt-12 md:mt-8 text-white text-xl md:text-md">
          <img
            src={CorrectIcon}
            loading="lazy"
            height="60"
            width="40"
            alt=""
            className="mb-4"
          />
          <h3 className="pb-4">
            ติดตั้งและใช้งานง่าย&nbsp;
            <br />
          </h3>
          <p className="font-graphikthloop">
            • ติดตั้งได้เองในไม่กี่ขั้นตอน
            <br />
            • ไม่ต้องใช้คีย์เวิร์ด
            <br />
            • เข้าระบบผ่านแอป 1convo Console
            <br />
          </p>
        </div>
        <div className="mt-12 md:mt-8 text-white text-xl md:text-md ">
          <img
            src={CorrectIcon}
            loading="lazy"
            height="60"
            width="40"
            alt=""
            className="mb-4"
          />
          <h3 className="pb-4">
            เชื่อมต่อแพลตฟอร์มได้หลากหลาย
            <br />
          </h3>
          <p>
            • ใช้งานแชทบอทบน LINE และ Facebook
            <br />
            • ระบบขนส่ง SHIPPOP
            <br />
            • ระบบรับชำระเงิน (Payment Gateway)
            <br />
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center mt-12">
        <a href="https://console.1convo.ai/register">
          <Button className="px-10 text-xl" variant="dark" size="medium">
            สมัครใช้งานวันนี้
          </Button>
        </a>
      </div>
    </div>
  </div>
);

export default FeatureList;
