import React, { useState } from "react";
import "./testimonials.css";
import Image1 from "../../../../static/image/campaign/card-image-1.png";
import Company1 from "../../../../static/image/campaign/company-1.png";
import Company2 from "../../../../static/image/campaign/company-2.png";
import Company3 from "../../../../static/image/campaign/company-3.png";
import Writer1 from "../../../../static/image/campaign/writer-1.png";
import Writer2 from "../../../../static/image/campaign/writer-2.png";
import Writer3 from "../../../../static/image/campaign/writer-3.png";
import Image2 from "../../../../static/image/campaign/card-image-2.png";
import Image3 from "../../../../static/image/campaign/card-image-3.png";
import Image4 from "../../../../static/image/campaign/card-image-4.png";
import arrow1 from "../../../../static/icon/campaign/arrow-1.svg";
import arrow2 from "../../../../static/icon/campaign/arrow-2.svg";
type CardPropsType = {
  children?: React.ReactNode;
};

enum IMAGE_TYPE {
  No1 = "no1",
  No2 = "no2",
  No3 = "no3",
  No4 = "no4",
}

const contentList = [
  {
    label: "จัดการเวลาทำงานของแอดมินและแชทบอทได้ตามต้องการ",
    writer: "คุณโกญจนาท ศรมยุรา",
    position: "กรรมการผู้จัดการ",
    company: "บริษัท เกษมชัยฟาร์มกรุ๊ป จำกัด",
    description:
      "ปกติเราใช้แอดมิน 10 คน พอเราเปลี่ยนมาใช้ 1convo เราเหลือแอดมิน 3 คน แล้วให้แอดมินไปทำงานส่วนอื่นได้ครับ",
    imageType: IMAGE_TYPE.No1,
    companyImage: <img className="company-image-1" src={Company1} />,
    writerImage: <img className="writer-image" src={Writer1} />,
  },
  {
    label: "รวมแชทลูกค้าจากทุกแพลตฟอร์ม",
    writer: "คุณเสฎฐวุฒิ ประสิทธิ์",
    position: "Digital Marketing Specialist",
    company: "Voice Plaza by Voice TV",
    description:
      "เราสามารถเอาเวลาของเราและทีม ไปพัฒนาสิ่งอื่นได้ เพราะเราไม่ต้องกังวลเรื่องการตอบแชทและรับออเดอร์แล้ว",
    imageType: IMAGE_TYPE.No2,
    companyImage: <img className="company-image" src={Company2} />,
    writerImage: <img className="writer-image" src={Writer2} />,
  },
  {
    label: "จัดการออเดอร์พิมพ์ใบเสร็จและใบปะหน้าพัสดุ",
    writer: "คุณธันยวดี วะสีนนท์",
    position: "รองประธานฝ่ายการตลาด",
    company: "บริษัท ไทยอินโนฟู้ด จำกัด",
    description:
      "ชอบที่ 1convo มีเทคโนโลยี AI ที่สามารถแยกสินค้าที่คล้ายคลึงกันได้จดจำโปรโมชันแทนแอดมินได้ ทำให้แอดมินทำงานได้ง่ายขึ้น",
    imageType: IMAGE_TYPE.No3,
    companyImage: <img className="company-image" src={Company3} />,
    writerImage: <img className="writer-image" src={Writer3} />,
  },
];

const CardImage: React.FC<CardPropsType> = () => {
  return (
    <div className="wrapper-testimonials">
      <div className="card-scroller-container lg:px-8">
        <div>
          <div>
            <div className="flex justify-center">
              <div className="testimonials-header text-center lg:px-0 px-5 pb-12">
                <h2 className="video-sub-heading-2 mb-1">Testimonials</h2>
                <h3 className="font-weight-600 text-gradient card-integration-heading pb-2 w-fit-content">
                  1convo ระบบจัดการร้านค้าออนไลน์ พิสูจน์แล้วโดยผู้ใช้งานจริง
                </h3>
              </div>
            </div>
            <div className="card-testimonials-content-wrapper">
              {contentList.map((item) => {
                const CompanyImage = item.companyImage;
                const WriterImage = item.writerImage;
                return (
                  <div key={item.imageType}>
                    <div className="card-layout relative">
                      <img className="arrow-image-1" src={arrow1} />
                      <img className="arrow-image-2" src={arrow2} />
                      <div className="card-testimonials-content">
                        {CompanyImage}
                        <div className="text-center card-testimonials-label">
                          {item.description}
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      {WriterImage}
                      <div className="detail">
                        <div className="writer-name">{item.writer}</div>
                        <div className="position-and-company">
                          <div>{item.position}</div>
                          <div>{item.company}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cards-scroller-testimonials">
            <div className="dp-cards-scroller-content">
              <div className="card-testimonials-content-wrapper-mobile">
                {contentList.map((item) => {
                  const CompanyImage = item.companyImage;
                  const WriterImage = item.writerImage;
                  return (
                    <div key={item.imageType}>
                      <div className="card-layout relative">
                        <img className="arrow-image-1" src={arrow1} />
                        <img className="arrow-image-2" src={arrow2} />
                        <div className="card-testimonials-content">
                          {CompanyImage}
                          <div className="text-center card-testimonials-desc">
                            {item.description}
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        {WriterImage}
                        <div className="detail">
                          <div className="writer-name">{item.writer}</div>
                          <div className="position-and-company">
                            <div>{item.position}</div>
                            <div>{item.company}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage;
