import React from "react";
import "../index.css";
import LottiePlayer from "../../LottiePlayer";
import ConversationStart from "../../../../static/lottie/02-1.json";
import ShopByYourself from "../../../../static/lottie/02-3.json";
import SendPicture from "../../../../static/lottie/02-2.json";
type CardPropsType = {
  children?: React.ReactNode;
};

const CardScrollerShopping: React.FC<CardPropsType> = () => {
  const cardDetails = [
    {
      text1: "ผ่านการแชท",
      text2: "ซื้อสินค้าผ่านแชทบอทหรือแอดมิน",
      videoLink: ConversationStart,
      isDarkBackground: false,
      width: "80%",
    },
    {
      text1: "ผ่านรูปภาพ",
      text2: "ส่งภาพสินค้าให้แชทบอทช่วยค้นหาทันที",
      videoLink: SendPicture,
    },
    {
      text1: "In-Chat Web Shopping",
      text2: "เลือกซื้อและจัดการตะกร้าสินค้า ด้วยตัวเอง",
      videoLink: ShopByYourself,
      width: "90%",
    },
  ];
  return (
    <div className="relative">
      <div className="card-scroller-container p-6">
        <div>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            หลากหลายทางเลือกการช็อป&nbsp;
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            เพื่อลูกค้าของคุณ
          </span>
        </div>
      </div>
      <div className="dp-cards-scroller">
        <div className="dp-cards-scroller-crop-shipping">
          <div className="dp-cards-scroller-content">
            <div className="dp-cards-scroller-platter">
              {cardDetails.map((card) => {
                return (
                  <div className="dp-cards-scroller-item-view" key={card.text1}>
                    <div className="dp-card-width-360 dp-card-height-400">
                      <div className="dp-card-content dp-card-height-400">
                        <div className="dp-card-img-full-wrapper mt-12">
                          <div className="absolute bottom-0 w-full">
                            <LottiePlayer
                              key={card.text1}
                              file={card.videoLink}
                              width={card.width || "100%"}
                              height={"auto"}
                              isDarkBackground={card.isDarkBackground || false}
                            />
                          </div>
                        </div>
                        <div className="dp-card-content-info">
                          <h3 className="dp-card-content-eyebrow">
                            {card.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <a className="dp-card-content-header-link">
                              {card.text2}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrollerShopping;
