import React, { useEffect, useState } from "react";
import "./cardImage.css";
import Image1 from "../../../../static/image/campaign/card-image-1.png";
import SubImage1 from "../../../../static/icon/campaign/card-sub-image-1.svg";
import Image2 from "../../../../static/image/campaign/card-image-2.png";
import SubImage2 from "../../../../static/icon/campaign/card-sub-image-2.svg";
import Image3 from "../../../../static/image/campaign/card-image-3.png";
import SubImage3 from "../../../../static/icon/campaign/card-sub-image-3.svg";
import Image4 from "../../../../static/image/campaign/card-image-4.png";
import SubImage4 from "../../../../static/icon/campaign/card-sub-image-4.svg";
type CardPropsType = {
  children?: React.ReactNode;
};

enum IMAGE_TYPE {
  No1 = "no1",
  No2 = "no2",
  No3 = "no3",
  No4 = "no4",
}

const contentList = [
  {
    label: "จัดการเวลาทำงานของแอดมินและแชทบอทได้ตามต้องการ",
    imageType: IMAGE_TYPE.No1,
    subImageComponent: <img className="sub-image" src={SubImage1} />,
    imageSrc: Image1,
  },
  {
    label: "รวมแชทลูกค้าจากทุกแพลตฟอร์ม",
    imageType: IMAGE_TYPE.No2,
    subImageComponent: <img className="sub-image" src={SubImage2} />,
    imageSrc: Image2,
  },
  {
    label: "จัดการออเดอร์ พิมพ์ใบเสร็จและใบปะหน้าพัสดุ",
    imageType: IMAGE_TYPE.No3,
    subImageComponent: <img className="sub-image" src={SubImage3} />,
    imageSrc: Image3,
  },
  {
    label: "รายงานการขายและข้อมูลลูกค้า",
    imageType: IMAGE_TYPE.No4,
    subImageComponent: <img className="sub-image" src={SubImage4} />,
    imageSrc: Image4,
  },
];

const CardImage: React.FC<CardPropsType> = () => {
  const [imageSrc, setImageSrc] = useState(Image1);
  const [selectedImage, setSelectedImage] = useState(IMAGE_TYPE.No1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [intervalFn, setIntervalFn] = useState<any>();

  useEffect(() => {
    let currentIndex = activeIndex;
    setImageSrc(contentList[currentIndex].imageSrc);
    setSelectedImage(contentList[currentIndex].imageType);
    if (intervalFn) {
      clearInterval(intervalFn);
    }
    setIntervalFn(
      setInterval(() => {
        setImageSrc(contentList[currentIndex].imageSrc);
        setSelectedImage(contentList[currentIndex].imageType);
        currentIndex =
          currentIndex === contentList.length - 1 ? 0 : currentIndex + 1;
      }, 2000)
    );
  }, [activeIndex]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="card-image-wrapper">
      <div className="card-scroller-container">
        <div className="lg:flex lg:gap-12">
          <div className="mobile-image lg:px-0 px-5">
            <img
              src={imageSrc}
              className="object-contain w-full md:w-9/12 lg:w-full"
              height={310}
              width={360}
            />
          </div>
          <div>
            <div className="mobile-header lg:px-0 pb-12 pl-6 lg:pl-0">
              <h2 className="video-sub-heading-2 mb-1">
                1convo Store Management
              </h2>
              <h3 className="font-weight-600 text-gradient card-integration-heading pb-2 w-fit-content">
                ระบบจัดการร้านค้า
              </h3>
              <h3 className="card-integration-heading">
                ช่วยงานขายตั้งแต่ต้นจนจบ
              </h3>
            </div>
            <div className="card-content-wrapper">
              {contentList.map((item, index) => {
                const Image = item.subImageComponent;
                return (
                  <div
                    key={item.imageType}
                    style={{
                      ...(selectedImage === item.imageType
                        ? {
                            background: `linear-gradient(
                      90deg,
                      #8632b1 8.49%,
                      #e43491 40.99%,
                      #e85b4e 73.5%
                    )`,
                          }
                        : { background: "transparent" }),
                      borderRadius: "20px",
                      padding: "3px",
                    }}
                  >
                    <div
                      className="card-content"
                      onClick={() => handleClick(index)}
                    >
                      {Image}
                      <div className="text-center leading-5 card-label">
                        {item.label}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:hidden dp-cards-scroller dp-card-height-image">
            <div className="dp-cards-scroller-crop-image">
              <div className="dp-cards-scroller-content">
                <div className="dp-cards-scroller-platter-mobile">
                  {contentList.map((item) => {
                    const Image = item.subImageComponent;
                    return (
                      <div
                        key={item.imageType}
                        className="dp-cards-scroller-item-view-mobile"
                      >
                        <div className="card-image-scroller">
                          <div className="flex justify-center pt-8 card-main-image">
                            <img
                              src={item.imageSrc}
                              className="object-contain w-5/6 md:w-9/12 lg:w-full"
                              height={310}
                              width={360}
                            />
                          </div>
                          <div className="card-image-scroller-content">
                            {Image}
                            <div className="text-2xl leading-7">
                              {item.label}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage;
